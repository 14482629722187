import Config from "@kaspernj/api-maker/src/config"
import I18nOnSteroids from "i18n-on-steroids"

const i18n = new I18nOnSteroids({
  fallbacks: {
    da: ["da", "en", "de"],
    de: ["da", "en", "da"],
    en: ["en", "de", "da"]
  }
})
const baseFiles = require.context("../../../config/locales", true, /^.\/([a-z]+)\.yml$/)
const dateTimeFiles = require.context("../../../config/locales/awesome_translations/date_time", true, /^(.+)\.yml$/)
const jsFiles = require.context("../../../config/locales/awesome_translations/js", true, /^(.+)\.yml$/)
const modelFiles = require.context("../../../config/locales/awesome_translations/models", true, /^(.+)\.yml$/)
const numbersFiles = require.context("../../../config/locales/awesome_translations/numbers", true, /^(.+)\.yml$/)
const routetTranslatorFiles = require.context("../../../config/locales/route_translator", true, /^(.+)\.yml$/)

i18n.scanRequireContext(baseFiles)
i18n.scanRequireContext(dateTimeFiles)
i18n.scanRequireContext(jsFiles)
i18n.scanRequireContext(modelFiles)
i18n.scanRequireContext(numbersFiles)
i18n.scanRequireContext(routetTranslatorFiles)

const locale = document.querySelector("html").getAttribute("lang")

i18n.setLocale(locale)
i18n.setLocaleOnStrftime()

Config.setI18n(i18n)
I18nOnSteroids.setCurrent(i18n)

export default i18n
